<template>
  <div class>
    <van-nav-bar left-arrow @click-left="$router.back()">
      <div slot="title" class="nav-title">
        <span style="margin-right: 2vw">{{ title }}</span>
      </div>
      <div slot="right" class="right-btn">
        <!-- 加入隐患经验库 -->
        <van-icon
          v-if="+troubleInfo.disposeStatus === 5 && requestEnd && btnAuth.join"
          class="right-btn-icon icon-join"
          :name="require('@/assets/images/trouble/join.png')"
          @click="onJoin"
        />
        <!-- 隐患流程 -->
        <van-icon
          v-if="requestEnd"
          class-prefix="iconfont"
          class="right-btn-icon"
          name="gzl3"
          @click="goFlowPage"
        />
      </div>
    </van-nav-bar>
    <div class="block-space-left-right">
      <step-progress
        :active-thickness="5"
        :current-stage="currentStage"
        :current-step="currentStep"
        :finished="finished"
        :line-thickness="4"
        :passive-thickness="3"
        :steps="mySteps"
        active-color="#1890ff"
        icon-class="van-icon van-icon-success"
        other-icon="iconfont iconfont-ico-ProgressDot"
        passive-color="#EBEBEB"
        @item-click="handlerClick"
      />
    </div>
    <div class="main">
      <transition mode="out-in" name="page-toggle">
        <router-view ref="router-view" />
      </transition>
    </div>
  </div>
</template>

<script>
import StepProgress from "@/views/trouble/components/vue-step-progress/vue-step-progress.min";
import "@/views/trouble/components/vue-step-progress/main.css";
import { Dialog, Toast } from "vant";
import {
  getTroubleInfoById,
  authAllowApproves,
  quickAddApproveExperience
} from "@/api/psm/trouble";
import { loading } from "@/utils/constant";
import { troubleStatusMap, clickMap as clickMap2 } from "./utils/constant";
import { mapState } from "vuex";
import cloneDeep from "lodash/cloneDeep";

// 隐患详情调度中心
export default {
  components: {
    StepProgress
  },
  props: ["id"],
  provide() {
    return { detailsComponents: this };
  },
  data() {
    return {
      // 当前我应该停留的阶段
      currentStep: 0,
      // 当前项目已经到的总阶段
      currentStage: 0,
      // 是否已经完成
      finished: false,
      mySteps: ["登记", "确认", "接收", "整改", "验收"],
      title: "隐患整改",
      troubleInfo: {},
      fileList: [],
      isAuthAllow: false,
      loading: { ...loading },
      troubleStatusMap: cloneDeep(troubleStatusMap),
      requestEnd: false
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo,
      btnAuth: state => state.menu.btnAuth
    }),
    isDiscoverer() {
      return this.troubleInfo.discoverer === this.userInfo.id;
    }
  },
  created() {
    if (this.id) {
      this.getTroubleInfo(this.id);
    }
  },

  methods: {
    goFlowPage() {
      this.$router.push({
        name: "trouble-flow",
        query: { id: this.id, status: this.troubleInfo?.disposeStatus }
      });
    },
    // 是否有下一步的权限
    isAuthAllowApproves() {
      return new Promise((resolve, reject) => {
        return authAllowApproves(this.id).then(resolve, reject);
      });
    },
    formatterFile(result) {
      return result?.files?.map(file => {
        return {
          url: `${window.globalConfig.VUE_APP_FILE_URL}/file/download?downloadFlag=false&fileName=${file.id}`,
          name: file.name,
          fileInfo: file,
          isImage: true
        };
      });
    },
    async getTroubleInfo() {
      try {
        this.requestEnd = false;
        const res = await getTroubleInfoById(this.id);
        this.requestEnd = true;
        this.troubleInfo = res;
        this.fileList = (res && this.formatterFile(res)) || [];
        // 八种状态，控制跳转
        // {"label": "待提交", "value": "0"},
        // {"label": "待审批","value": "1"},
        // {"label": "待整改", "value": "2"},
        // {"label": "待延期审批", "value": "3"},
        // {"label": "待验收", "value": "4"},
        // {"label": "已完成", "value": "5"},
        // {"label": "待接收", "value": "7"},
        // {"label": "已取消", "value": "8" }

        const disposeStatus = this.troubleInfo?.disposeStatus;
        if (disposeStatus == null) return;
        if (+disposeStatus === 5) this.finished = true;
        const currentTroubleConf = this.troubleStatusMap[disposeStatus];

        this.isAuthAllow = await this.isAuthAllowApproves();
        // this.isAuthAllow = true;
        // this.isAuthAllow = false;

        currentTroubleConf.query.add = this.isAuthAllow;
        this.title = currentTroubleConf.title;
        const { route: routerName, query: routerQuery } = currentTroubleConf;
        // 当前应该到的总状态
        this.currentStage = currentTroubleConf.currentStage;
        if (this.isAuthAllow) {
          this.currentStep = currentTroubleConf.currentStep;
          if (this.finished) {
            await this.$router.replace({
              name: "trouble-check",
              query: { add: false }
            });
          } else if (disposeStatus === 0) {
            await this.$router.replace({
              name: "trouble-add",
              query: { add: true, id: this.id }
            });
          } else if (disposeStatus === 9 && this.isDiscoverer) {
            await this.$router.replace({
              name: "trouble-add",
              query: { add: true, id: this.id, disposeStatus: 9 }
            });
          } else {
            await this.$router.replace({
              name: routerName,
              query: { ...routerQuery }
            });
          }
        } else {
          //  控制我可以处于的阶段
          if (!this.finished) {
            const currentStep = (currentTroubleConf.currentStep -= 1);
            this.currentStep = currentStep < 0 ? 0 : currentStep;
            //  回到上一个阶段的页面
            const lastRoute = currentTroubleConf.lastRoute;
            await this.$router.replace({
              name: lastRoute,
              query: { add: false }
            });
          } else if (disposeStatus === 0) {
            await this.$router.replace({
              name: "trouble-add",
              query: { add: false, id: this.id }
            });
          } else {
            this.currentStep = currentTroubleConf.currentStep;
            await this.$router.replace({
              name: "trouble-check",
              query: { add: false }
            });
          }
        }
      } catch (e) {
        console.log("getTroubleInfo -> e", e);
      }
    },
    handlerClick(ev, step, idx) {
      const disposeStatus = +this.troubleInfo.disposeStatus;
      const clickMap = cloneDeep(clickMap2);
      const queryAddMap = {
        0: 0,
        1: 1,
        // 接收状态是 7
        2: 7,
        // 待整改是 2 或者延期整改 3
        3: 2,
        // 待验收是 4
        4: 4
      };
      Object.keys(clickMap).forEach(key => {
        clickMap[key].status = disposeStatus;
        clickMap[key].query.add =
          this.isAuthAllow && disposeStatus === queryAddMap[key];
      });

      if (disposeStatus !== 5) {
        if (this.isAuthAllow) {
          if (idx > this.currentStage) return;
        } else {
          if (idx >= this.currentStage) return Toast("您不是指定处理人");
        }
      }

      const currentClickConf = clickMap[idx];
      const { routeName: name, query, currentStep } = currentClickConf;

      if (idx === 3) {
        const map = {
          2: "trouble-modify",
          3: "trouble-delay-approval"
        };
        const isFixStatus = disposeStatus === 3 || disposeStatus === 2;
        const query = {
          add: this.isAuthAllow && isFixStatus
        };
        const name = map[isFixStatus ? disposeStatus : 2];
        this.$router.replace({ name, query });
      } else {
        this.$router.replace({ name, query });
      }
      this.currentStep = currentStep;
    },
    onJoin() {
      Dialog.confirm({
        message: "是否要将该隐患加入隐患经验库？"
      }).then(() => {
        quickAddApproveExperience(this.id).then(res => {
          console.log(res, "res");
          if (res) {
            Toast("操作成功");
          }
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.main {
  height: 70vh;
  overflow: auto;
}
.mr2 {
  margin-right: 15px;
}
.ml2 {
  margin-left: 15px;
}
.right-btn {
  height: 58px;
}
.right-btn-icon {
  font-size: 24px;
}
.icon-join {
  margin-right: 16px;
  vertical-align: text-bottom;
}
.nav-title {
  display: flex;
  justify-content: center;
  align-items: center;
}
.dialog-button {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
  .van-button {
    width: 35vw;
  }
  .van-button--plain {
    background-color: #dbedff;
  }
}
</style>
